import { template as template_bf54def77ac6465ba12c79a1301f7698 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bf54def77ac6465ba12c79a1301f7698(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
