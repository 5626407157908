import { template as template_82539dba78ad4f099ca85e20f377d6c5 } from "@ember/template-compiler";
const FKText = template_82539dba78ad4f099ca85e20f377d6c5(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
