import { template as template_c8160becab144e62bc6cfa87ce0084f9 } from "@ember/template-compiler";
const SidebarSectionMessage = template_c8160becab144e62bc6cfa87ce0084f9(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
